header {
  position: fixed;
  z-index: 10000;
  width: 100%;
  background-color: $bc01;

  @include sp {
    width: 100%;
  }

  .head_wrap {
    .head-top {
      transition: all 0.4s ease-in-out; /*アニメーションの設定*/
      padding: 30px 46px 30px 34px;
      &.scroll-nav {
        transition: all 0.4s ease-in-out; /*アニメーションの設定*/
        padding: 20px 46px 20px 34px;
        @include sp {
          padding: 10px 10px 10px 10px;
        }
        .logo {
          transition: all 0.4s ease-in-out; /*アニメーションの設定*/
          width: 225px;
          @include sp {
            width: 50%;
          }
        }
        .btn {
          p {
            font-size: 2.3rem;
            color: #fff100;
          }
        }
      }
      @include sp {
        padding: 10px 10px 10px 10px;
      }
      .logo {
        width: 325px;
        &:hover {
          opacity: 0.8;
        }
        @include pc {
          width: 40%;
          @include sp {
            width: 50%;
          }
        }
      }
      .btn {
        border: 5px solid #fff100;
        padding: 6px 10px 3px 10px;
        &:hover {
          opacity: 0.8;
        }
        a {
          display: block;
        }
        p {
          font-size: 2.7rem;
          color: #fff100;
        }
      }
      /*ボタン外側※レイアウトによってpositionや形状は適宜変更してください*/
      .openbtn1 {
        position: relative; /*ボタン内側の基点となるためrelativeを指定*/
        background: #fff;
        cursor: pointer;
        width: 50px;
        height: 50px;
        border-radius: 5px;
        z-index: 1000;
      }

      /*ボタン内側*/
      .openbtn1 span {
        display: inline-block;
        transition: all 0.4s; /*アニメーションの設定*/
        position: absolute;
        left: 8px;
        height: 3px;
        border-radius: 2px;
        background: $bc01;
        width: 70%;
      }

      .openbtn1 span:nth-of-type(1) {
        top: 15px;
      }

      .openbtn1 span:nth-of-type(2) {
        top: 23px;
      }

      .openbtn1 span:nth-of-type(3) {
        top: 31px;
      }

      /*activeクラスが付与されると線が回転して×に*/

      .openbtn1.active span:nth-of-type(1) {
        top: 18px;
        left: 11px;
        transform: translateY(6px) rotate(-45deg);
        width: 60%;
      }

      .openbtn1.active span:nth-of-type(2) {
        opacity: 0; /*真ん中の線は透過*/
      }

      .openbtn1.active span:nth-of-type(3) {
        top: 30px;
        left: 11px;
        transform: translateY(-6px) rotate(45deg);
        width: 60%;
      }
    }
    .menu__box {
      transition: all 0.4s; /*アニメーションの設定*/
      position: absolute;
      top: 0;
      display: none;
      background-color: $bc01;
      padding-top: 100px;
      width: 100%;
      &.active {
        display: block;
      }
      li {
        font-size: 2rem;
        text-align: center;
        border-bottom: 1px solid #fff;
        width: 90%;
        margin: 0 auto 30px;
        padding-bottom: 20px;
        &:last-child {
          border-bottom: none;
        }
        a {
          color: #fff;
          display: block;
        }
      }
    }
  }
}

footer {
  background-color: $bc01;
  .foot_wrap {
    padding: 52px 75px 40px 75px;
    @include sp {
      padding: 30px 15px;
    }
    .foot-content {
      align-items: flex-start;

      div {
        @include sp {
          width: 45%;
        }
        ul {
          &:nth-child(2) {
            padding-top: 40px;
            @include sp {
              padding-top: 0;
            }
          }
          li {
            margin-bottom: 15px;
            @include sp {
              margin-bottom: 10px;
            }
            &:last-child {
              margin-bottom: 0;
              @include sp {
                margin-bottom: 10px;
              }
            }
            a {
              color: #fff;
              font-size: 1.5rem;
              @include sp {
                font-size: 1.2rem;
              }
              &:hover {
                opacity: 0.8;
              }
            }
          }
        }
      }
    }
  }
  .copy {
    text-align: center;
    color: #fff;
    background-color: $bc01;
    padding-bottom: 10px;
    @include sp {
      margin-bottom: 50px;
    }
  }
  .yellow-txt {
    background-color: #fff100;
    position: fixed;
    color: $bc01;
    font-size: 2.5rem;
    font-weight: bold;
    width: 100%;
    text-align: center;
    bottom: 0;
    a {
      padding: 10px 0;
      display: block;
      color: $bc01;
    }
  }
}
