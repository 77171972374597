main {
  padding-top: 119px;
  @include sp {
    padding-top: 69px;
  }
  .mv {
    margin-bottom: 20px;
  }
  .bnr-slider {
    li {
      margin-right: 20px;
      a {
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .head-nav {
    position: sticky;
    top: 93.5px;
    background-color: #fff;
    z-index: 100;

    ul {
      padding: 30px 0;
      li {
        width: 25%;
        display: block;
        text-align: center;
        border-left: 5px solid $bc01;
        padding: 7px 0;
        font-size: 2.2rem;
        &:last-child {
          border-right: 5px solid $bc01;
        }
        a {
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
  .btns {
    margin-bottom: 66px;
    @include sp {
      margin-bottom: 33px;
    }
    ul {
      li {
        width: 33.333333333333333%;
        &.two-btn {
          position: relative;
          &:hover {
            opacity: 1;
          }
        }

        &:hover {
          opacity: 0.8;
        }
        &:nth-child(4) {
          width: 50%;
          @include sp {
            width: 100%;
          }
        }
        &:nth-child(5) {
          width: 50%;
          @include sp {
            width: 100%;
          }
        }
        @include sp {
          width: 100%;
          margin-bottom: 20px;
        }
        .Left {
          width: 27%;
          position: absolute;
          bottom: 20%;
          left: 10%;
          &:hover {
            opacity: 0.8;
          }
        }
        .Right {
          width: 27%;
          position: absolute;
          bottom: 20%;
          left: 40%;
          &:hover {
            opacity: 0.8;
          }
        }
        &.orange-two-btn {
          .Right {
            width: 18%;
            left: 50%;
          }
          .Left {
            width: 18%;
            left: 30.5%;
          }
        }
      }
    }
  }

  .photo-content {
    background-image: url(../img/top/bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 50px;
    @include sp {
      margin-bottom: 33px;
    }
    .inner {
      padding: 253px 0;
      width: 86%;
      margin: 0 auto;
      @include sp {
        width: 80%;
        padding: 100px 0;
      }
      div {
        width: 46%;
        @include h_sd;
        @include sp {
          width: 100%;
          &:first-child {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  .educational-philosophy {
    width: 80%;
    margin: 0 auto 50px;
    @include h_sd;
    @include sp {
      width: 100%;
      margin-bottom: 33px;
    }
  }
  .kousya-nav {
    background-color: #e8edf6;
    margin-bottom: 48px;
    .inner {
      padding: 20px 75px 50px 75px;
      @include sp {
        padding: 10px 25px 50px 25px;
      }
    }
    .ttl {
      width: 585px;
      margin: 0 auto 48px;
      @include pc {
        width: 80%;
        @include sp {
          margin-bottom: 20px;
          width: 90%;
        }
      }
    }
    ul {
      li {
        width: 18%;
        @include h_sd;
        @include sp {
          width: 30%;
        }
        img {
          max-width: 100%;
        }
      }
      &.sp-flex {
        margin-top: 20px;
        justify-content: center;
        gap: 20px;
        li {
          width: 30%;
        }
      }
    }
  }
  .yellow-txt {
    width: 80%;
    background-color: #fff100;

    margin: 0 auto 60px;
    @include sp {
      width: 100%;
      margin-bottom: 30px;
    }
    a {
      display: block;
      padding: 10px 0;
      &:hover {
        opacity: 0.8;
      }
    }
    p {
      color: $bc01;
      font-size: 2.8rem;
      text-align: center;
      font-weight: 600;
      @include sp {
        font-size: 2rem;
      }
    }
  }
  .chara {
    width: 80%;
    margin: 0 auto 60px;
    @include h_sd;
    @include sp {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  .slick-dotted.slick-slider {
    margin-bottom: 30px;
    @include sp {
      margin-bottom: 60px;
    }
  }

  .slick-dots li button:before {
    font-family: 'slick';
    font-size: 50px;
    line-height: 30px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    // opacity: 0.25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @include sp {
      font-size: 30px;
      left: 10px;
    }
    .slick-dots li.slick-active button:before {
      opacity: 0.25 !important;
      color: black;
    }
  }

  .slick-prev::before,
  .slick-next::before {
    content: ' ';
    display: inline-block;
    width: 30px;
    height: 30px;
    transform: rotate(45deg);
    width: 30px;
    height: 30px;
    @include sp {
      width: 15px;
      height: 15px;
    }
  }
  .slick-prev {
    left: -2vw;
    @include sp {
      left: 5vw;
    }
  }
  .slick-next {
    right: -2vw;
    @include sp {
      right: 5vw;
    }
  }
  .slick-prev::before {
    border-left: 3px solid #999;
    border-bottom: 3px solid #999;
  }
  .slick-next::before {
    border-top: 3px solid #999;
    border-right: 3px solid #999;
  }
}

// スライド追加

.c-slider {
  margin-bottom: 16px;
  padding: 0;
  overflow-x: hidden !important;
}

.c-slider__link {
  margin-top: 0;
  text-align: center;
}

.c-slider .c-slider__item::after {
  content: none;
}

.c-slider__slide {
  text-align: center;
}
.c-slider__content {
  margin-bottom: 15px;
}

.c-slider__wrap {
  position: relative;
}

.c-slider__nav {
  position: relative;
  /*max-width: 70px;*/
  max-width: 200px;
  margin-right: auto;
  margin-left: auto;
}

.c-slider__nav .swiper-pagination {
  position: static;
}

.c-slider__nav .swiper-pagination-bullet {
  opacity: 1;
  width: 5px;
  height: 5px;
  margin: 0 2px;
  background-color: #ddd;
}

.c-slider__nav .swiper-pagination-bullet-active {
  background-color: #1e4c97;
}

.c-slider__nav .swiper-button-prev,
.c-slider__nav .swiper-button-next {
  top: 38%;
  transform: translateY(50%);
  height: 100%;
}

.c-slider__nav .swiper-button-prev {
  left: -8px;
}

.c-slider__nav .swiper-button-next {
  right: -8px;
}

.c-slider__nav .swiper-button-next:after,
.c-slider__nav .swiper-button-prev:after {
  width: 6px;
  height: 6px;
  border: 1px solid;
  font-family: initial;
  content: '';
}

.c-slider__nav .swiper-button-prev:after {
  transform: rotate(45deg);
  border-color: transparent transparent #000 #000;
}

.c-slider__nav .swiper-button-next:after {
  transform: rotate(45deg);
  border-color: #000 #000 transparent transparent;
}

.c-slider__nav {
  /*max-width: 120px;*/
  max-width: 260px;
}
.c-slider__nav .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
  margin: 0 4px;
  @include sp {
    width: 10px;
    height: 10px;
  }
}
.c-slider__nav .swiper-button-next,
.c-slider__nav .swiper-button-prev {
  top: 22%;
  @include sp {
    top: 60%;
  }
}
.c-slider__nav .swiper-button-next:after,
.c-slider__nav .swiper-button-prev:after {
  width: 13px;
  height: 13px;
}
