@charset "UTF-8";
/*** reset ***/
* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-indent: 0;
}

img {
  image-rendering: -webkit-optimize-contrast;
  max-width: 100%;
  height: auto;
  border: none;
  vertical-align: bottom;
  margin: 0;
  padding: 0;
}

address,
em,
h1,
h2,
h3,
h4,
h5,
h6,
ins,
pre,
strong,
th {
  font-style: normal;
  font-weight: 400;
}

table {
  border-collapse: collapse;
  border: 0;
}

th {
  text-align: left;
}

input,
select,
textarea {
  vertical-align: middle;
}

a,
ins {
  text-decoration: none;
}

ul {
  list-style: none;
}

br {
  letter-spacing: 0;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

/*** font ***/
html {
  font-size: 62.5%;
}

body {
  line-height: 1.5;
  font-feature-settings: "palt";
  font-size: 1.4rem;
  letter-spacing: 1px;
  color: #000;
}
@media (max-width: 768px) {
  body {
    font-size: 1.2rem;
  }
}

/*family*/
body {
  font-family: "Hiragino Kaku Gothic Pro", "KozGoPr6N", Meiryo, YuGothic, sans-serif;
  font-weight: 500;
}
@media (max-width: 768px) {
  body {
    font-weight: 400;
  }
}

/*** link ***/
a,
a:active,
a:hover,
a:link,
a:visited {
  color: #000;
}

/***PC／スマホ切替***/
.sp {
  display: none;
}

.pc {
  display: block;
}

@media screen and (max-width: 768px) {
  .sp {
    display: block;
  }
  .pc {
    display: none;
  }
}
.base_w {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1200px) {
  .base_w {
    width: 90%;
  }
}
@media (max-width: 1200px) and (max-width: 768px) {
  .base_w {
    width: 100%;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

header {
  position: fixed;
  z-index: 10000;
  width: 100%;
  background-color: #1e4c97;
}
@media (max-width: 768px) {
  header {
    width: 100%;
  }
}
header .head_wrap .head-top {
  transition: all 0.4s ease-in-out; /*アニメーションの設定*/
  padding: 30px 46px 30px 34px;
  /*ボタン外側※レイアウトによってpositionや形状は適宜変更してください*/
  /*ボタン内側*/
  /*activeクラスが付与されると線が回転して×に*/
}
header .head_wrap .head-top.scroll-nav {
  transition: all 0.4s ease-in-out; /*アニメーションの設定*/
  padding: 20px 46px 20px 34px;
}
@media (max-width: 768px) {
  header .head_wrap .head-top.scroll-nav {
    padding: 10px 10px 10px 10px;
  }
}
header .head_wrap .head-top.scroll-nav .logo {
  transition: all 0.4s ease-in-out; /*アニメーションの設定*/
  width: 225px;
}
@media (max-width: 768px) {
  header .head_wrap .head-top.scroll-nav .logo {
    width: 50%;
  }
}
header .head_wrap .head-top.scroll-nav .btn p {
  font-size: 2.3rem;
  color: #fff100;
}
@media (max-width: 768px) {
  header .head_wrap .head-top {
    padding: 10px 10px 10px 10px;
  }
}
header .head_wrap .head-top .logo {
  width: 325px;
}
header .head_wrap .head-top .logo:hover {
  opacity: 0.8;
}
@media (max-width: 1200px) {
  header .head_wrap .head-top .logo {
    width: 40%;
  }
}
@media (max-width: 1200px) and (max-width: 768px) {
  header .head_wrap .head-top .logo {
    width: 50%;
  }
}
header .head_wrap .head-top .btn {
  border: 5px solid #fff100;
  padding: 6px 10px 3px 10px;
}
header .head_wrap .head-top .btn:hover {
  opacity: 0.8;
}
header .head_wrap .head-top .btn a {
  display: block;
}
header .head_wrap .head-top .btn p {
  font-size: 2.7rem;
  color: #fff100;
}
header .head_wrap .head-top .openbtn1 {
  position: relative; /*ボタン内側の基点となるためrelativeを指定*/
  background: #fff;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  z-index: 1000;
}
header .head_wrap .head-top .openbtn1 span {
  display: inline-block;
  transition: all 0.4s; /*アニメーションの設定*/
  position: absolute;
  left: 8px;
  height: 3px;
  border-radius: 2px;
  background: #1e4c97;
  width: 70%;
}
header .head_wrap .head-top .openbtn1 span:nth-of-type(1) {
  top: 15px;
}
header .head_wrap .head-top .openbtn1 span:nth-of-type(2) {
  top: 23px;
}
header .head_wrap .head-top .openbtn1 span:nth-of-type(3) {
  top: 31px;
}
header .head_wrap .head-top .openbtn1.active span:nth-of-type(1) {
  top: 18px;
  left: 11px;
  transform: translateY(6px) rotate(-45deg);
  width: 60%;
}
header .head_wrap .head-top .openbtn1.active span:nth-of-type(2) {
  opacity: 0; /*真ん中の線は透過*/
}
header .head_wrap .head-top .openbtn1.active span:nth-of-type(3) {
  top: 30px;
  left: 11px;
  transform: translateY(-6px) rotate(45deg);
  width: 60%;
}
header .head_wrap .menu__box {
  transition: all 0.4s; /*アニメーションの設定*/
  position: absolute;
  top: 0;
  display: none;
  background-color: #1e4c97;
  padding-top: 100px;
  width: 100%;
}
header .head_wrap .menu__box.active {
  display: block;
}
header .head_wrap .menu__box li {
  font-size: 2rem;
  text-align: center;
  border-bottom: 1px solid #fff;
  width: 90%;
  margin: 0 auto 30px;
  padding-bottom: 20px;
}
header .head_wrap .menu__box li:last-child {
  border-bottom: none;
}
header .head_wrap .menu__box li a {
  color: #fff;
  display: block;
}

footer {
  background-color: #1e4c97;
}
footer .foot_wrap {
  padding: 52px 75px 40px 75px;
}
@media (max-width: 768px) {
  footer .foot_wrap {
    padding: 30px 15px;
  }
}
footer .foot_wrap .foot-content {
  align-items: flex-start;
}
@media (max-width: 768px) {
  footer .foot_wrap .foot-content div {
    width: 45%;
  }
}
footer .foot_wrap .foot-content div ul:nth-child(2) {
  padding-top: 40px;
}
@media (max-width: 768px) {
  footer .foot_wrap .foot-content div ul:nth-child(2) {
    padding-top: 0;
  }
}
footer .foot_wrap .foot-content div ul li {
  margin-bottom: 15px;
}
@media (max-width: 768px) {
  footer .foot_wrap .foot-content div ul li {
    margin-bottom: 10px;
  }
}
footer .foot_wrap .foot-content div ul li:last-child {
  margin-bottom: 0;
}
@media (max-width: 768px) {
  footer .foot_wrap .foot-content div ul li:last-child {
    margin-bottom: 10px;
  }
}
footer .foot_wrap .foot-content div ul li a {
  color: #fff;
  font-size: 1.5rem;
}
@media (max-width: 768px) {
  footer .foot_wrap .foot-content div ul li a {
    font-size: 1.2rem;
  }
}
footer .foot_wrap .foot-content div ul li a:hover {
  opacity: 0.8;
}
footer .copy {
  text-align: center;
  color: #fff;
  background-color: #1e4c97;
  padding-bottom: 10px;
}
@media (max-width: 768px) {
  footer .copy {
    margin-bottom: 50px;
  }
}
footer .yellow-txt {
  background-color: #fff100;
  position: fixed;
  color: #1e4c97;
  font-size: 2.5rem;
  font-weight: bold;
  width: 100%;
  text-align: center;
  bottom: 0;
}
footer .yellow-txt a {
  padding: 10px 0;
  display: block;
  color: #1e4c97;
}

.top_page main {
  padding-top: 119px;
}
@media (max-width: 768px) {
  .top_page main {
    padding-top: 69px;
  }
}
.top_page main .mv {
  margin-bottom: 20px;
}
.top_page main .bnr-slider li {
  margin-right: 20px;
}
.top_page main .bnr-slider li a:hover {
  opacity: 0.8;
}
.top_page main .head-nav {
  position: sticky;
  top: 93.5px;
  background-color: #fff;
  z-index: 100;
}
.top_page main .head-nav ul {
  padding: 30px 0;
}
.top_page main .head-nav ul li {
  width: 25%;
  display: block;
  text-align: center;
  border-left: 5px solid #1e4c97;
  padding: 7px 0;
  font-size: 2.2rem;
}
.top_page main .head-nav ul li:last-child {
  border-right: 5px solid #1e4c97;
}
.top_page main .head-nav ul li a:hover {
  opacity: 0.8;
}
.top_page main .btns {
  margin-bottom: 66px;
}
@media (max-width: 768px) {
  .top_page main .btns {
    margin-bottom: 33px;
  }
}
.top_page main .btns ul li {
  width: 33.3333333333%;
}
.top_page main .btns ul li.two-btn {
  position: relative;
}
.top_page main .btns ul li.two-btn:hover {
  opacity: 1;
}
.top_page main .btns ul li:hover {
  opacity: 0.8;
}
.top_page main .btns ul li:nth-child(4) {
  width: 50%;
}
@media (max-width: 768px) {
  .top_page main .btns ul li:nth-child(4) {
    width: 100%;
  }
}
.top_page main .btns ul li:nth-child(5) {
  width: 50%;
}
@media (max-width: 768px) {
  .top_page main .btns ul li:nth-child(5) {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .top_page main .btns ul li {
    width: 100%;
    margin-bottom: 20px;
  }
}
.top_page main .btns ul li .Left {
  width: 27%;
  position: absolute;
  bottom: 20%;
  left: 10%;
}
.top_page main .btns ul li .Left:hover {
  opacity: 0.8;
}
.top_page main .btns ul li .Right {
  width: 27%;
  position: absolute;
  bottom: 20%;
  left: 40%;
}
.top_page main .btns ul li .Right:hover {
  opacity: 0.8;
}
.top_page main .btns ul li.orange-two-btn .Right {
  width: 18%;
  left: 50%;
}
.top_page main .btns ul li.orange-two-btn .Left {
  width: 18%;
  left: 30.5%;
}
.top_page main .photo-content {
  background-image: url(../img/top/bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  .top_page main .photo-content {
    margin-bottom: 33px;
  }
}
.top_page main .photo-content .inner {
  padding: 253px 0;
  width: 86%;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .top_page main .photo-content .inner {
    width: 80%;
    padding: 100px 0;
  }
}
.top_page main .photo-content .inner div {
  width: 46%;
  transition: all 0.3s;
}
.top_page main .photo-content .inner div:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}
@media (max-width: 1000px) {
  .top_page main .photo-content .inner div:hover {
    transform: translateY(0px);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
}
@media (max-width: 768px) {
  .top_page main .photo-content .inner div {
    width: 100%;
  }
  .top_page main .photo-content .inner div:first-child {
    margin-bottom: 20px;
  }
}
.top_page main .educational-philosophy {
  width: 80%;
  margin: 0 auto 50px;
  transition: all 0.3s;
}
.top_page main .educational-philosophy:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}
@media (max-width: 1000px) {
  .top_page main .educational-philosophy:hover {
    transform: translateY(0px);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
}
@media (max-width: 768px) {
  .top_page main .educational-philosophy {
    width: 100%;
    margin-bottom: 33px;
  }
}
.top_page main .kousya-nav {
  background-color: #e8edf6;
  margin-bottom: 48px;
}
.top_page main .kousya-nav .inner {
  padding: 20px 75px 50px 75px;
}
@media (max-width: 768px) {
  .top_page main .kousya-nav .inner {
    padding: 10px 25px 50px 25px;
  }
}
.top_page main .kousya-nav .ttl {
  width: 585px;
  margin: 0 auto 48px;
}
@media (max-width: 1200px) {
  .top_page main .kousya-nav .ttl {
    width: 80%;
  }
}
@media (max-width: 1200px) and (max-width: 768px) {
  .top_page main .kousya-nav .ttl {
    margin-bottom: 20px;
    width: 90%;
  }
}
.top_page main .kousya-nav ul li {
  width: 18%;
  transition: all 0.3s;
}
.top_page main .kousya-nav ul li:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}
@media (max-width: 1000px) {
  .top_page main .kousya-nav ul li:hover {
    transform: translateY(0px);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
}
@media (max-width: 768px) {
  .top_page main .kousya-nav ul li {
    width: 30%;
  }
}
.top_page main .kousya-nav ul li img {
  max-width: 100%;
}
.top_page main .kousya-nav ul.sp-flex {
  margin-top: 20px;
  justify-content: center;
  gap: 20px;
}
.top_page main .kousya-nav ul.sp-flex li {
  width: 30%;
}
.top_page main .yellow-txt {
  width: 80%;
  background-color: #fff100;
  margin: 0 auto 60px;
}
@media (max-width: 768px) {
  .top_page main .yellow-txt {
    width: 100%;
    margin-bottom: 30px;
  }
}
.top_page main .yellow-txt a {
  display: block;
  padding: 10px 0;
}
.top_page main .yellow-txt a:hover {
  opacity: 0.8;
}
.top_page main .yellow-txt p {
  color: #1e4c97;
  font-size: 2.8rem;
  text-align: center;
  font-weight: 600;
}
@media (max-width: 768px) {
  .top_page main .yellow-txt p {
    font-size: 2rem;
  }
}
.top_page main .chara {
  width: 80%;
  margin: 0 auto 60px;
  transition: all 0.3s;
}
.top_page main .chara:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}
@media (max-width: 1000px) {
  .top_page main .chara:hover {
    transform: translateY(0px);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
}
@media (max-width: 768px) {
  .top_page main .chara {
    width: 100%;
    margin-bottom: 30px;
  }
}
.top_page main .slick-dotted.slick-slider {
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .top_page main .slick-dotted.slick-slider {
    margin-bottom: 60px;
  }
}
.top_page main .slick-dots li button:before {
  font-family: "slick";
  font-size: 50px;
  line-height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (max-width: 768px) {
  .top_page main .slick-dots li button:before {
    font-size: 30px;
    left: 10px;
  }
}
.top_page main .slick-dots li button:before .slick-dots li.slick-active button:before {
  opacity: 0.25 !important;
  color: black;
}
.top_page main .slick-prev::before,
.top_page main .slick-next::before {
  content: " ";
  display: inline-block;
  width: 30px;
  height: 30px;
  transform: rotate(45deg);
  width: 30px;
  height: 30px;
}
@media (max-width: 768px) {
  .top_page main .slick-prev::before,
.top_page main .slick-next::before {
    width: 15px;
    height: 15px;
  }
}
.top_page main .slick-prev {
  left: -2vw;
}
@media (max-width: 768px) {
  .top_page main .slick-prev {
    left: 5vw;
  }
}
.top_page main .slick-next {
  right: -2vw;
}
@media (max-width: 768px) {
  .top_page main .slick-next {
    right: 5vw;
  }
}
.top_page main .slick-prev::before {
  border-left: 3px solid #999;
  border-bottom: 3px solid #999;
}
.top_page main .slick-next::before {
  border-top: 3px solid #999;
  border-right: 3px solid #999;
}
.top_page .c-slider {
  margin-bottom: 16px;
  padding: 0;
  overflow-x: hidden !important;
}
.top_page .c-slider__link {
  margin-top: 0;
  text-align: center;
}
.top_page .c-slider .c-slider__item::after {
  content: none;
}
.top_page .c-slider__slide {
  text-align: center;
}
.top_page .c-slider__content {
  margin-bottom: 15px;
}
.top_page .c-slider__wrap {
  position: relative;
}
.top_page .c-slider__nav {
  position: relative;
  /*max-width: 70px;*/
  max-width: 200px;
  margin-right: auto;
  margin-left: auto;
}
.top_page .c-slider__nav .swiper-pagination {
  position: static;
}
.top_page .c-slider__nav .swiper-pagination-bullet {
  opacity: 1;
  width: 5px;
  height: 5px;
  margin: 0 2px;
  background-color: #ddd;
}
.top_page .c-slider__nav .swiper-pagination-bullet-active {
  background-color: #1e4c97;
}
.top_page .c-slider__nav .swiper-button-prev,
.top_page .c-slider__nav .swiper-button-next {
  top: 38%;
  transform: translateY(50%);
  height: 100%;
}
.top_page .c-slider__nav .swiper-button-prev {
  left: -8px;
}
.top_page .c-slider__nav .swiper-button-next {
  right: -8px;
}
.top_page .c-slider__nav .swiper-button-next:after,
.top_page .c-slider__nav .swiper-button-prev:after {
  width: 6px;
  height: 6px;
  border: 1px solid;
  font-family: initial;
  content: "";
}
.top_page .c-slider__nav .swiper-button-prev:after {
  transform: rotate(45deg);
  border-color: transparent transparent #000 #000;
}
.top_page .c-slider__nav .swiper-button-next:after {
  transform: rotate(45deg);
  border-color: #000 #000 transparent transparent;
}
.top_page .c-slider__nav {
  /*max-width: 120px;*/
  max-width: 260px;
}
.top_page .c-slider__nav .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
  margin: 0 4px;
}
@media (max-width: 768px) {
  .top_page .c-slider__nav .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
  }
}
.top_page .c-slider__nav .swiper-button-next,
.top_page .c-slider__nav .swiper-button-prev {
  top: 22%;
}
@media (max-width: 768px) {
  .top_page .c-slider__nav .swiper-button-next,
.top_page .c-slider__nav .swiper-button-prev {
    top: 60%;
  }
}
.top_page .c-slider__nav .swiper-button-next:after,
.top_page .c-slider__nav .swiper-button-prev:after {
  width: 13px;
  height: 13px;
}