/*** reset ***/

* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-indent: 0;
}

img {
  image-rendering: -webkit-optimize-contrast;
  max-width: 100%;
  height: auto;
  border: none;
  vertical-align: bottom;
  margin: 0;
  padding: 0;
}

address,
em,
h1,
h2,
h3,
h4,
h5,
h6,
ins,
pre,
strong,
th {
  font-style: normal;
  font-weight: 400;
}

table {
  border-collapse: collapse;
  border: 0;
}

th {
  text-align: left;
}

input,
select,
textarea {
  vertical-align: middle;
}

a,
ins {
  text-decoration: none;
}

ul {
  list-style: none;
}

br {
  letter-spacing: 0;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

/*** font ***/

html {
  font-size: 62.5%;
}

body {
  line-height: 1.5;
  font-feature-settings: 'palt';
  font-size: 1.4rem;
  letter-spacing: 1px;
  color: #000;
  @include sp {
    font-size: 1.2rem;
  }
}

/*family*/

body {
  font-family: 'Hiragino Kaku Gothic Pro', 'KozGoPr6N', Meiryo, YuGothic,
    sans-serif;
  font-weight: 500;
  @include sp {
    font-weight: 400;
  }
}

/*** link ***/

a,
a:active,
a:hover,
a:link,
a:visited {
  color: #000;
}

/***PC／スマホ切替***/

.sp {
  display: none;
}

.pc {
  display: block;
}

@media screen and (max-width: ($sp)) {
  .sp {
    display: block;
  }

  .pc {
    display: none;
  }
}

.base_w {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  @include pc {
    width: 90%;
    @include sp {
      width: 100%;
    }
  }
}

// Flex

.flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

// ホバー

@mixin h_sd {
  transition: all 0.3s;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);

    @include tab {
      transform: translateY(0px);
      box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
  }
}
